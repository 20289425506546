import { PlatformUser, PlatformUserService } from '../../../services/platform-user/platform-user.service';

import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { finalize } from 'rxjs';
import { PaginatedResponse } from '../../../models/paginated-response.model';

@Component({
	selector: 'app-team',

	templateUrl: './team.component.html',

	styleUrls: ['./team.component.scss'],
})
export class TeamComponent {
	constructor(private platformUserService: PlatformUserService,) { }

	public rows: any = [];
	public search = '';
	public pageSize = 10;
	public page = 0;
	public selector: any = {};
	public displayedColumns: string[] = ['enabled', 'name', 'email', 'role', 'action'];
	public dataSource: PlatformUser[] = [];
	public apiLabel = '';
	public apiDescription = '';
	public apiData = { api_key: '', api_secret: '' };
	public openCreateModal = false;
	public openConfirmModal = false;
	public loadindStatusChange = false;
	public merchantId = '';
	public isLoading = false;
	public openSuccessModal = false;
	public loadingStates: { [key: string]: boolean } = {};
	public selectedUser: PlatformUser = {
		name: '',
		email: '',
		enabled: false
	};
	public isEdit = false;
	private cacheAPI = sessionStorage;

	ngOnInit(): void {
		this.getTeamInfo();
		this.merchantId = JSON.parse(
			this.cacheAPI.getItem('user') || '[]'
		).merchant_id;
	}

	public onPageChange(event: PageEvent) {
		this.pageSize = event.pageSize;

		this.page = event.pageIndex;

		this.getTeamInfo();
	}

	public getTeamInfo() {
		this.isLoading = true;

		this.platformUserService
			.getPlatformUsers(this.search, this.page, this.pageSize)
			.subscribe(({ content, totalElements }: PaginatedResponse<PlatformUser>) => {
				this.dataSource = content;

				this.selector = totalElements;

				this.isLoading = false;
			});
	}

	public toggleModal() {
		this.openCreateModal = !this.openCreateModal;
	}

	public closeModal() {
		this.selectedUser = {
			name: '',
			email: '',
			enabled: false,
		};
		this.isEdit = false;
		this.openCreateModal = false;
	}

	public createUserMerchant() {
		this.isLoading = true;
		this.platformUserService.postPlatformUser(this.selectedUser.name, this.selectedUser.email, this.merchantId).pipe(finalize(() => {
			this.toggleConfirmModal();
			this.closeModal();
		})).subscribe({
			next: () => {
				this.toggleSuccessModal();
				this.isLoading = false;
			},
			error: () => {
				this.isEdit = false;
			}
		});
	}

	public saveUserMerchant() {
		this.platformUserService.putPlatformUser(this.selectedUser.name, this.selectedUser.email, this.merchantId).pipe(finalize(() => {
			this.toggleConfirmModal();
			this.closeModal();
		})).subscribe();
	}

	public editUserMerchant(user: PlatformUser) {
		this.selectedUser = user;
		this.isEdit = true;

		this.toggleModal();
	}

	public toggleConfirmModal() {
		this.openConfirmModal = !this.openConfirmModal;
	}


	public toggleActiveUser(element: PlatformUser) {
		if (element.id) {
			this.loadingStates[element.id] = true;
			this.platformUserService.patchPlatformUser(element.id, element.enabled).subscribe(
				{
					next: () => {
						if (element.id) {
							this.loadingStates[element.id] = false;
						}
					},
					error: () => {
						element.enabled = !element.enabled;
						if (element.id) {
							this.loadingStates[element.id] = false;
						}
					}
				}
			);
		}
	}

	public toggleSuccessModal() {
		this.openSuccessModal = !this.openSuccessModal;
		if (!this.openSuccessModal) {
			this.getTeamInfo();
		}
	}

}
