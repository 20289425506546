import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'ngx-cookie-service';
import { CurrencyModel } from '../../models/currency.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import { environment } from '../../../environments/environment';

export interface AuthenticationData {
  user: User;
  version: string;
  token: string;
}

export interface LoginData {
  username: string;
  password: string;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private baseUrl = `${environment.apiURL}/auth`;
	private cacheToken = sessionStorage;
	private currentToken: string | null = null;
	private token: string | null = '';
	public userData: User | null = null;
	public api: any = null;
	public wait = false;
	public selectedCurrency: CurrencyModel = { asset: '', amount: 0 };
	public recaptchaToken = '';

	constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService
	) {
		this.getAuthenticationData();
	}

	public authenticateUser(
		loginData: LoginData
	): Observable<AuthenticationData> {
		const url = this.baseUrl;

		const headers = new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
			recaptchaToken: this.recaptchaToken,
		});

		const body = new URLSearchParams();
		body.set('username', loginData.username);
		body.set('password', loginData.password);
		body.set('grant_type', 'password');

		return this.http.post<AuthenticationData>(url, body, { headers });
	}

	public getUserList(): Observable<any> {
		const url = `${this.baseUrl}/list`;

		return this.http.get(url);
	}

	public logout(): void {
		sessionStorage.clear();
		this.cookieService.delete('authToken');
		this.router.navigate(['/login']);
	}

	public revalidateToken(): void {
		if (!this.wait) {
			this.wait = true;

			if (!this.userData || !this.cacheToken.getItem('pwd')) {
				this.router.navigate(['/login']);
			} else {
				const credentials = {
					username: this.userData.username,
					password: atob(this.cacheToken.getItem('pwd') || ''),
				};

				this.authenticateUser(credentials);
			}
		}
	}

	// SETTERS

	public setToken(token: string): void {
		this.cookieService.delete('authToken');
		this.cookieService.set('authToken', token, undefined, '/');
	}

	public deleteToken(): void {
		this.cookieService.delete('authToken');
	}

	public setRecaptcha(token: string): void {
		this.recaptchaToken = token;
	}

	public setAuthenticationData(data: AuthenticationData): void {
		this.token = data.token;

		if (this.token) {
			this.cookieService.set('authToken', this.token);
			this.setUser(data.user);
			this.setAPIVersion(data.version);
		}
	}

	public setAPIVersion(version: any): void {
		this.cacheToken.setItem('api', JSON.stringify(version));
	}

	public setPassword(password: any): void {
		this.cacheToken.setItem('pwd', btoa(password));
	}

	public setUser(user: User): void {
		this.userData = user;
		this.cacheToken.setItem('user', JSON.stringify(this.userData));
	}

	public removeToken(): void {
		this.cookieService.delete('authToken');
	}

	// GETTERS
	public getToken(): string {
		return this.cookieService.get('authToken');
	}

	public getAuthenticationData(): void {
		this.token = this.cookieService.get('authToken');
		this.setDefaultHeaderToken(this.token);
		this.getUser();
		this.getAPIVersion();
	}

	public getUser(): void {
		this.userData = null;
		const cacheUserData = this.cacheToken.getItem('user');
		if (cacheUserData) {
			this.userData = JSON.parse(cacheUserData);
		}
	}

	getAPIVersion(): void {
		this.api = this.cacheToken.getItem('api');
	}

	public setDefaultHeaderToken(token: string | null): void {
		this.currentToken = token;
	}

	public getDefaultHeaderToken(): string | null {
		return this.currentToken;
	}
}
