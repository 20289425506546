import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class MerchantService {
	public baseUrl = `${environment.apiURL}/merchant`;

	constructor(private http: HttpClient) { }

	public getMerchant(): Observable<any> {
		const url = `${this.baseUrl}`;

		return this.http.get(url);
	}

	public getAccounts() {
		const url = `${this.baseUrl}/account`;

		return this.http.get(url);
	}

	public getApiKeys(
		search = '',
		page = 0,
		pageSize = 10
	) {
		const url = `${environment.apiURL}/access-key`;
		let queryParams = new HttpParams();
		queryParams = queryParams.append('search', search);
		queryParams = queryParams.append('page', page);
		queryParams = queryParams.append('pageSize', pageSize);

		return this.http.get(url, { params: queryParams });
	}

	public postApiKeys(label: string, description: string) {
		const url = `${environment.apiURL}/access-key`;
		return this.http.post(url, { label: label, description: description });
	}
}
