import { RouterModule, Routes } from '@angular/router';

import { ApiKeysComponent } from './components/merchant/api-keys/api-keys.component';
import { AuthGuard } from './guards/auth.guard';
import { BalanceComponent } from './components/payouts/balance/balance.component';
import { BankAccountsComponent } from './components/merchant/bank-accounts/bank-accounts.component';
import { BusinessInfoComponent } from './components/merchant/business-info/business-info.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DeclinesComponent } from './components/payments/declines/declines.component';
import { DefaultComponent } from './layouts/default/default.component';
import { DisputeDetailsComponent } from './components/disputes/dispute-details/dispute-details.component';
import { DisputesComponent } from './components/disputes/disputes.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { FirewallRulesComponent } from './components/payments/firewall-rules/firewall-rules.component';
import { IdProjectsComponent } from './components/id-validation/projects/id-projects.component';
import { IdServiceStatementsComponent } from './components/id-validation/id-service-statements/id-service-statements.component';
import { IdTransactionReportsComponent } from './components/id-validation/id-transaction-reports/id-transaction-reports.component';
import { LoginComponent } from './components/login/login.component';
import { LogsComponent } from './components/logs/logs.component';
import { NgModule } from '@angular/core';
import { PayoutsProjectsComponent } from './components/payouts/payouts-projects/payouts-projects.component';
import { PayoutsTransactionDetailsComponent } from './components/payouts/payouts-transaction-details/payouts-transaction-details.component';
import { PayoutsTransactionReportsComponent } from './components/payouts/reports/payouts-transaction-reports/payouts-transaction-reports.component';
import { ProjectsComponent } from './components/payments/projects/projects.component';
import { RefundComponent } from './components/refund/refund.component';
import { RefundsReportComponent } from './components/payments/refunds-report/refunds-report.component';
import { RevenuesComponent } from './components/payments/revenues/revenues.component';
import { ServiceSettlementsComponent } from './components/payments/service-settlements/service-settlements.component';
import { SettlementsComponent } from './components/payments/settlements/settlements.component';
import { StatementsDetailComponent } from './components/id-validation/statements-detail/statements-detail.component';
import { TeamComponent } from './components/merchant/team/team.component';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { TransactionReportsComponent } from './components/payments/transaction-reports/transaction-reports.component';
import { TransfersComponent } from './components/payments/transfers/transfers.component';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'login',
	},
	{
		path: 'login',
		component: EmptyLayoutComponent,
		children: [{ path: '', component: LoginComponent }],
	},
	{
		path: '',
		component: DefaultComponent,
		canActivate: [AuthGuard],
		children: [
			{ path: 'dashboard', component: DashboardComponent },
			{
				path: 'payments/transaction-report',
				component: TransactionReportsComponent,
			},
			{ path: 'payments/errors-and-declines', component: DeclinesComponent },
			{ path: 'payments/revenues', component: RevenuesComponent },
			{ path: 'payments/resolution-center', component: DisputesComponent },
			{
				path: 'payments/resolution-center/dispute-details/:id',
				component: DisputeDetailsComponent,
			},
			{
				path: 'payments/service-settlements',
				component: ServiceSettlementsComponent,
			},
			{ path: 'payments/settlements', component: SettlementsComponent },
			{ path: 'payments/refunds-report', component: RefundsReportComponent },
			{ path: 'payments/transfers', component: TransfersComponent },
			{ path: 'payments/refund', component: RefundComponent },
			{ path: 'payments/contracts', component: ProjectsComponent },
			{ path: 'payments/firewall-rules', component: FirewallRulesComponent },
			{ path: 'merchant/bank-accounts', component: BankAccountsComponent },
			{ path: 'merchant/business-info', component: BusinessInfoComponent },
			{ path: 'contracts', component: ProjectsComponent },
			{ path: 'legal/merchant-agreement', component: ProjectsComponent },
			{ path: 'legal/tcs', component: ProjectsComponent },
			{
				path: 'payments/transaction-report/detail',
				component: TransactionDetailComponent,
			},
			{
				path: 'id-validation/reports/statement-detail',
				component: StatementsDetailComponent,
			},
			{
				path: 'id-validation/reports/transaction-report',
				component: IdTransactionReportsComponent,
			},
			{
				path: 'id-validation/reports/service-statements',
				component: IdServiceStatementsComponent,
			},
			{ path: 'id-validation/contracts', component: IdProjectsComponent },

			{ path: 'logs', component: LogsComponent },
			{ path: 'merchant/team', component: TeamComponent },
			{ path: 'merchant/api-keys', component: ApiKeysComponent },
			{
				path: 'payouts/reports/transaction-reports',
				component: PayoutsTransactionReportsComponent,
			},
			{
				path: 'payouts/reports/transaction-reports/details',
				component: PayoutsTransactionDetailsComponent,
			},
			{ path: 'payouts/balance', component: BalanceComponent },
			{ path: 'payouts/contracts', component: PayoutsProjectsComponent },
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
