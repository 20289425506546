import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '../../models/paginated-response.model';
import { environment } from '../../../environments/environment';

export interface PlatformUser {
	id?: string;
	name: string;
	email: string;
	enabled: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class PlatformUserService {
	private baseUrl = `${environment.apiURL}/platform-user`;

	constructor(private http: HttpClient) { }


	public getPlatformUsers(search: string, page: number, pageSize: number): Observable<PaginatedResponse<PlatformUser>> {

		let queryParams = new HttpParams();
		queryParams = search
			? queryParams.append('search', search)
			: queryParams;

		queryParams = page
			? queryParams.append('page', page)
			: queryParams;

		queryParams = pageSize
			? queryParams.append('pageSize', pageSize)
			: queryParams;

		return this.http.get<PaginatedResponse<PlatformUser>>(`${this.baseUrl}`, { params: queryParams });
	}

	public postPlatformUser(name: string, email: string, merchantId: string) {

		return this.http.post<PlatformUser>(`${this.baseUrl}`, { name: name, email: email, relatedId: merchantId });

	}

	public patchPlatformUser(id: string, enabled: boolean) {
		return this.http.patch<PlatformUser>(`${this.baseUrl}/${id}`, { enable: enabled });

	}

	public putPlatformUser(name: string, email: string, merchantId: string) {
		return this.http.put<PlatformUser>(`${this.baseUrl}/${merchantId}`, { name: name, email: email, relatedId: merchantId });

	}
}
