<div class="mt-5">
  <app-fieldset tittle="Filters">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>Search:</mat-label>
      <input matInput [(ngModel)]="search" placeholder="Search for a user" />
    </mat-form-field>
    <button mat-flat-button color="primary" (click)="getTeamInfo()">
      Filter
    </button>
  </app-fieldset>

  <app-fieldset tittle="Actions">
    <button (click)="toggleModal()" mat-flat-button color="primary">
      Add Member
    </button>
  </app-fieldset>
  <div class="row">
    <app-card [cardTitle]="''">
      <table
        mat-table
        [dataSource]="dataSource"
        class="transactions-table"
        [loader]="isLoading"
      >
        <ng-container matColumnDef="enabled">
          <th mat-header-cell *matHeaderCellDef>Active</th>
          <td
            mat-cell
            *matCellDef="let element"
            style="text-align: center"
            [loader]="loadingStates[element.id]"
          >
            <mat-slide-toggle
              color="primary"
              [(ngModel)]="element.enabled"
              (click)="toggleActiveUser(element)"
            ></mat-slide-toggle>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <div class="last-days-card">{{ element.name }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Role</th>
          <td mat-cell *matCellDef="let element">
            {{ element.authorityList }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editUserMerchant(element)">
              <mat-icon color="primary">edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </app-card>
  </div>
  <div class="row" style="width: 70%">
    <mat-paginator
      [pageSizeOptions]="[10, 20]"
      [pageSize]="pageSize"
      [length]="selector"
      (page)="onPageChange($event)"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<app-modal
  title="Create new member"
  [openModal]="openCreateModal"
  (closeModalRequest)="closeModal()"
>
  <div class="column">
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>Name:</mat-label>
      <input
        matInput
        [(ngModel)]="selectedUser.name"
        placeholder="Input name"
      />
    </mat-form-field>
    <mat-form-field class="letpay-input" appearance="outline">
      <mat-label>Email:</mat-label>
      <input
        matInput
        [(ngModel)]="selectedUser.email"
        placeholder="Input Email"
        [disabled]="isEdit"
      />
    </mat-form-field>
    <div class="row">
      <button mat-flat-button (click)="closeModal()">Cancel</button>
      <button mat-flat-button color="primary" (click)="toggleConfirmModal()">
        {{ isEdit ? "Edit member" : "Create member" }}
      </button>
    </div>
  </div>
</app-modal>

<app-modal
  [openModal]="openConfirmModal"
  minWidth="200px"
  title="Alert"
  (closeModalRequest)="toggleConfirmModal()"
>
  <div class="ml-5 mr-5">
    <p>
      Are you sure you want to <b>{{ isEdit ? "edit" : "create" }}</b> a user
      merchant?
    </p>
  </div>
  <div class="mb-5 mr-5" style="display: flex; justify-content: end">
    <button
      class="block-btn"
      style="width: 46px"
      (click)="toggleConfirmModal()"
    >
      No
    </button>
    <button
      class="send-btn ml-5"
      style="width: 46px"
      (click)="isEdit ? saveUserMerchant() : createUserMerchant()"
    >
      Yes
    </button>
  </div>
</app-modal>

<app-modal
  [openModal]="openSuccessModal"
  minWidth="200px"
  title="Success"
  (closeModalRequest)="toggleSuccessModal()"
>
  <div class="ml-5 mr-5">
    <p>User merchant created succesfully</p>
  </div>
  <div class="mb-5 mr-5" style="display: flex; justify-content: end">
    <button
    class="send-btn ml-5"
    style="width: 46px"
    (click)="toggleSuccessModal()"
  >
    Ok
  </button>
  </div>
</app-modal>
