import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

export interface DateFilterWithPagination {
  end: string;
  page: number;
  pageSize: number;
  search: string;
  start: string;
  status?: string;
  separatorType?: string;
}

export interface DateFilter {
  end: string;
  start: string;
}

@Injectable({
	providedIn: 'root',
})
export class PaymentsService {
	private baseUrl = `${environment.apiURL}/report`;
	private paymentsUrl = `${environment.apiURL}/payment`;

	constructor(private http: HttpClient) { }

	public postRevenuesReport(
		end: string = this.getCurrentDate(),
		start: string = this.getOneMonthAgoDate()
	): Observable<any> {
		const url = `${this.baseUrl}/totalPaymentMethodByPeriod`;

		const filter: DateFilter = {
			end: end,
			start: start,
		};
		return this.http.post<DateFilter>(url, filter);
	}

	public postReportDateFilter(
		reportURL: string,
		start: string = this.getOneMonthAgoDate(),
		end: string = this.getCurrentDate(),
		search = '',
		pageSize = 10,
		page = 0,
		status = ''
	): Observable<any> {
		const url = `${this.baseUrl}/${reportURL}`;

		let formattedStartDate = start;
		let formattedEndDate = end;

		if (start && end) {
			formattedStartDate = new Date(start).toISOString().split('T')[0];
			formattedEndDate = new Date(end).toISOString().split('T')[0];
		}

		const filter: any = {};

		if (formattedStartDate) {
			filter.start = formattedStartDate;
		}

		if (formattedEndDate) {
			filter.end = formattedEndDate;
		}

		if (status) {
			filter.status = status;
		}

		if (search) {
			filter.search = search;
		}

		if (pageSize) {
			filter.pageSize = pageSize;
		}

		if (page) {
			filter.page = page;
		}

		return this.http.post<DateFilter>(url, filter);
	}

	public getRefundAvailable(id: string) {
		const url = ` ${this.paymentsUrl}/availableToRefund/${id}/`;
		return this.http.get<any>(url);
	}

	public getReportDateFilter(
		reportURL: string,
		start: string = this.getOneMonthAgoDate(),
		end: string = this.getCurrentDate(),
		search = '',
		page: number,
		pageSize: number
	): Observable<any> {
		const filter: DateFilterWithPagination = {
			end: end,
			start: start,
			pageSize: pageSize,
			page: page,
			search: search,
		};

		let queryParams = new HttpParams();
		queryParams = filter.search
			? queryParams.append('search', filter.search)
			: queryParams;

		queryParams = filter.page
			? queryParams.append('page', filter.page)
			: queryParams;

		queryParams = filter.pageSize
			? queryParams.append('size', filter.pageSize)
			: queryParams;

		queryParams = filter.start
			? queryParams.append('start_date', filter.start)
			: queryParams;

		queryParams = filter.end
			? queryParams.append('end_date', filter.end)
			: queryParams;

		const url = `${this.baseUrl}/${reportURL}`;

		return this.http.get<any>(url, { params: queryParams });
	}

	public downloadTransactionDetailLog(id: string) {
		const url = `${environment.apiURL}/transactionDetails/${id}/csv`;

		return this.http.get(url, { responseType: 'text' });
	}

	public downloadCSV(
		downloadUrl: string,
		start: string = this.getOneMonthAgoDate(),
		end: string = this.getCurrentDate(),
		search = '',
		pageSize = 10000,
		page = 0,
		separatorType = 'comma'
	): Observable<any> {
		const url = `${this.baseUrl}/${downloadUrl}`;

		const filter: DateFilterWithPagination = {
			end: end,
			start: start,
			pageSize: 10000,
			page: page,
			search: search,
			separatorType: separatorType,
		};

		return this.http.post(url, filter, {
			responseType: 'text',
			params: { ...filter },
		});
	}

	public getPaymentDetail(paymentId: string) {
		const url = `${environment.apiURL}/transactionDetails/${paymentId}`;
		return this.http.get(url);
	}

	public postRefund(
		paymentToken: string,
		amount: number,
		refundData: any = {}
	): Observable<any> {
		const url = environment.apiURL + '/refund';

		return this.http.post(url, {
			payment_token: paymentToken,
			amount: amount,
			bank_code: refundData.bankCode,
			clabe: refundData.clabe,
			bank_number: refundData.bankNumber,
			agency_number: refundData.agencyNumber,
			agency_check_digit: refundData.agencyDigit,
			account_number: refundData.accountNumber,
			account_check_digit: refundData.accountDigit,
		});
	}

	public formatDate(date: string): string {
		if (date) {
			const stringToDate = new Date(date);
			const formattedDate = stringToDate.toISOString();

			return formattedDate.split('T')[0];
		}
		return '';
	}

	public getCurrentDate(): string {
		return new Date().toISOString().split('T')[0];
	}

	public getOneMonthAgoDate(): string {
		const oneMonthAgo = new Date();
		oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
		return oneMonthAgo.toISOString().split('T')[0];
	}

	public getPaymentStatuses() {
		const url = `${this.paymentsUrl}/statuses`;
		return this.http.get<any>(url);
	}
}
