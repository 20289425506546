import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MatNativeDateModule,
} from '@angular/material/core';

import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ArrowIconComponent } from '../assets/common/icons/arrow-icon/arrow-icon.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { CardComponent } from './components/card/card.component';
import { CurrencyModalComponent } from './components/currency-modal/currency-modal.component';
import { BalancesComponent } from './components/dashboard/balances/balances.component';
import { IndicesComponent } from './components/dashboard/components/indices/indices.component';
import { LineChartComponent } from './components/dashboard/components/line-chart/line-chart.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DisputeStatusCardComponent } from './components/dispute-status-card/dispute-status-card.component';
import { DisputeDetailsComponent } from './components/disputes/dispute-details/dispute-details.component';
import { DisputesComponent } from './components/disputes/disputes.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { DownloadCsvButtonComponent } from './components/download-csv-button/download-csv-button.component';
import { FieldsetComponent } from './components/fieldset/fieldset.component';
import { BlockModalComponent } from './components/firewall-rules/block-modal/block-modal.component';
import { ReblockModalComponent } from './components/firewall-rules/reblock-modal/reblock-modal.component';
import { UnblockModalComponent } from './components/firewall-rules/unblock-modal/unblock-modal.component';
import { HeaderComponent } from './components/header/header.component';
import { IdServiceStatementsComponent } from './components/id-validation/id-service-statements/id-service-statements.component';
import { IdTransactionReportsComponent } from './components/id-validation/id-transaction-reports/id-transaction-reports.component';
import { IdProjectsComponent } from './components/id-validation/projects/id-projects.component';
import { StatementsDetailComponent } from './components/id-validation/statements-detail/statements-detail.component';
import { LastDaysCardComponent } from './components/last-days-card/last-days-card.component';
import { MerchantAgreementComponent } from './components/legal/merchant-agreement/merchant-agreement.component';
import { TCsComponent } from './components/legal/tcs/tcs.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { LogsComponent } from './components/logs/logs.component';
import { ApiKeysComponent } from './components/merchant/api-keys/api-keys.component';
import { BankAccountsComponent } from './components/merchant/bank-accounts/bank-accounts.component';
import { BusinessInfoComponent } from './components/merchant/business-info/business-info.component';
import { TeamComponent } from './components/merchant/team/team.component';
import { ModalComponent } from './components/modal/modal.component';
import { DeclinesComponent } from './components/payments/declines/declines.component';
import { FirewallRulesComponent } from './components/payments/firewall-rules/firewall-rules.component';
import { ProjectsComponent } from './components/payments/projects/projects.component';
import { RefundsReportComponent } from './components/payments/refunds-report/refunds-report.component';
import { RevenuesComponent } from './components/payments/revenues/revenues.component';
import { ServiceSettlementsComponent } from './components/payments/service-settlements/service-settlements.component';
import { SettlementsComponent } from './components/payments/settlements/settlements.component';
import { TransactionReportsComponent } from './components/payments/transaction-reports/transaction-reports.component';
import { TransfersComponent } from './components/payments/transfers/transfers.component';
import { BalanceComponent } from './components/payouts/balance/balance.component';
import { PayoutsProjectsComponent } from './components/payouts/payouts-projects/payouts-projects.component';
import { PayoutsTransactionDetailsComponent } from './components/payouts/payouts-transaction-details/payouts-transaction-details.component';
import { PayoutsTransactionReportsComponent } from './components/payouts/reports/payouts-transaction-reports/payouts-transaction-reports.component';
import { PendingStatusCardComponent } from './components/pending-status-card/pending-status-card.component';
import { PercentageVariationCardComponent } from './components/percentage-variation-card/percentage-variation-card.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RefundComponent } from './components/refund/refund.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SettlementsTableComponent } from './components/settlements-table/settlements-table.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { StatusCardComponent } from './components/status-card/status-card.component';
import { TransactionDetailComponent } from './components/transaction-detail/transaction-detail.component';
import { LoaderDirective } from './directives/loader.directive';
import { AuthGuard } from './guards/auth.guard';
import { DefaultComponent } from './layouts/default/default.component';
import { EmptyLayoutComponent } from './layouts/empty-layout/empty-layout.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { ConvertCurrencyPipe } from './pipes/convert-currency.pipe';
import { FormatCurrencyFromToPipe } from './pipes/format-currency-from-to.pipe';
import { FormatCurrencyPipe } from './pipes/format-currency.pipe';
import { FormatTextPipe } from './pipes/format-text.pipe';
import { FormatThousandPipe } from './pipes/format-thousand.pipe';
import { SplitCamelCasePipe } from './pipes/split-camel-case.pipe';
import { SplitUnderscorePipe } from './pipes/split-underscore.pipe';
import { AuthInterceptor } from './services/HttpInterceptor';
import { APP_DATE_FORMATS } from './utils/app-date-formats';
import { UTCDateAdapter } from './utils/utc-date-adapter';

@NgModule({
	declarations: [
		AppComponent,
		BarChartComponent,
		LoginComponent,
		DashboardComponent,
		SideBarComponent,
		EmptyLayoutComponent,
		DefaultComponent,
		DeclinesComponent,
		RevenuesComponent,
		SettlementsComponent,
		RefundComponent,
		BusinessInfoComponent,
		BankAccountsComponent,
		ProjectsComponent,
		MerchantAgreementComponent,
		TCsComponent,
		HeaderComponent,
		TransactionReportsComponent,
		ServiceSettlementsComponent,
		TransfersComponent,
		FirewallRulesComponent,
		CardComponent,
		LastDaysCardComponent,
		IndicesComponent,
		PercentageVariationCardComponent,
		ArrowIconComponent,
		DonutChartComponent,
		SettlementsTableComponent,
		LineChartComponent,
		BalancesComponent,
		FormatCurrencyPipe,
		FormatThousandPipe,
		TransactionDetailComponent,
		IdTransactionReportsComponent,
		IdServiceStatementsComponent,
		IdProjectsComponent,
		LogsComponent,
		ApiKeysComponent,
		StatusCardComponent,
		BlockModalComponent,
		UnblockModalComponent,
		ModalComponent,
		SearchBarComponent,
		ReblockModalComponent,
		PayoutsTransactionReportsComponent,
		PayoutsProjectsComponent,
		ProfileComponent,
		CurrencyModalComponent,
		LoaderDirective,
		LoaderComponent,
		StatementsDetailComponent,
		PendingStatusCardComponent,
		FormatCurrencyFromToPipe,
		ConvertCurrencyPipe,
		RefundsReportComponent,
		SplitCamelCasePipe,
		FormatTextPipe,
		DisputesComponent,
		DisputeDetailsComponent,
		DisputeStatusCardComponent,
		SplitUnderscorePipe,
		BalanceComponent,
		PayoutsTransactionDetailsComponent,
		DownloadCsvButtonComponent,
		FieldsetComponent,
		MenuItemComponent,
		TeamComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		NgChartsModule,
		HttpClientModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatTableModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		FormsModule,
		MatFormFieldModule,
		MatPaginatorModule,
		MatSelectModule,
		MatCheckboxModule,
		SimplebarAngularModule,
		MatMenuModule,
		MatIconModule,
		MatButtonModule,
		MatRadioModule,
		MatProgressSpinnerModule,
		NgxCaptchaModule,
		MatTooltipModule,
		MatSlideToggleModule
	],
	exports: [LoaderDirective],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: DateAdapter, useClass: UTCDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
		AuthGuard,
		DatePipe,
		ConvertCurrencyPipe,
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
