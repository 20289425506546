import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { AuthService } from 'src/app/services/auth/auth-service.service';
import { Contract } from 'src/app/models/contract.model';
import { ContractsService } from 'src/app/services/projects/contracts.service';
import { MenuItem } from 'src/app/models/menu-item.model';
import { SideBarService } from 'src/app/services/side-bar/side-bar.service';
import { User } from 'src/app/models/user.model';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-side-bar',
	templateUrl: './side-bar.component.html',
	styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
	public basePath: string;
	public logoWidth = 120;
	public theme: string;
	public menuItems: MenuItem[] = [
		{ label: 'Dashboard', link: '/dashboard', parent: null },
		{
			label: 'Payments',
			parent: null,
			children: [
				{
					label: 'Reports',
					parent: null,
					children: [
						{
							label: 'Transactions',
							link: '/payments/transaction-report',
							parent: null,
						},
						{
							label: 'Errors & Declines',
							link: '/payments/errors-and-declines',
							parent: null,
						},
						{ label: 'Revenues', link: '/payments/revenues', parent: null },
						{
							label: 'Refunds',
							link: '/payments/refunds-report',
							parent: null,
						},
					],
				},
				{ label: 'Refund', link: '/payments/refund', parent: null },
				{ label: 'Contracts', link: '/payments/contracts', parent: null },
				{
					label: 'Firewall Rules',
					link: '/payments/firewall-rules',
					parent: null,
				},
				{
					label: 'Resolution Center',
					link: '/payments/resolution-center',
					parent: null,
				},
			],
		},
		{
			label: 'Payouts',
			parent: null,
			children: [
				{
					label: 'Reports',
					parent: null,
					children: [
						{
							label: 'Transaction Reports',
							link: '/payouts/reports/transaction-reports',
							parent: null,
						},
					],
				},
				{ label: 'Balance', link: '/payouts/balance', parent: null },
				{ label: 'Contracts', link: '/payouts/contracts', parent: null },
			],
		},
		{
			label: 'ID Validation',
			parent: null,
			children: [
				{
					label: 'Reports',
					parent: null,
					children: [
						{
							label: 'Transaction Reports',
							link: '/id-validation/reports/transaction-report',
							parent: null,
						},
						{
							label: 'Service Statements',
							link: '/id-validation/reports/service-statements',
							parent: null,
						},
					],
				},
				{ label: 'Contracts', link: '/id-validation/contracts', parent: null },
			],
		},
		{ label: 'Logs', link: '/logs', parent: null },
		{
			label: 'Merchant Settings',
			parent: null,
			children: [
				{
					label: 'Business Info',
					link: '/merchant/business-info',
					parent: null,
				},
				{
					label: 'Bank Accounts',
					link: '/merchant/bank-accounts',
					parent: null,
				},
				{
					label: 'Team',
					link: '/merchant/team',
					parent: null,
				},
				{ label: 'API Keys', link: '/merchant/api-keys', parent: null },
			],
		},
	];

	public expandedMenus: Set<string> = new Set();
	public isSidebarMinimized = false;
	public selectedMenu: MenuItem | null = null;
	public userData: User | null;
	public currencyModal = false;
	public personalInfoModal = false;
	private cacheAPI = sessionStorage;
	private unsubscribe$ = new Subject<void>();

	constructor(
    public router: Router,
    public sidebarService: SideBarService,
    private authService: AuthService,
    private contractsService: ContractsService
	) {
		this.theme = environment.theme;
		this.logoWidth = this.theme === 'skin-epag' ? 240 : 440;
		this.basePath =
      this.theme === 'skin-epag' ? '/assets/epag/' : '/assets/letpay/';
		this.userData = this.authService.userData;
	}

	ngOnInit(): void {
		this.assignParents(this.menuItems);
		this.selectMenuItemBasedOnRoute();

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.selectMenuItemBasedOnRoute();
			}
		});
		this.checkPayouts();
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	public selectMenuItem(item: MenuItem): void {
		this.selectedMenu = item;

		if (!this.isSidebarMinimized) {
			this.expandedMenus.clear();
			this.expandParents(item);
		}

		if (item.children && !this.isSidebarMinimized) {
			this.toggleMenu(item);
		}

		if (item.link) {
			this.router.navigate([item.link]);
		}
	}

	private expandParents(item: MenuItem): void {
		let parent = item.parent;
		while (parent) {
			this.expandedMenus.add(parent.label);
			parent = parent.parent;
		}
	}

	private assignParents(
		items: MenuItem[],
		parent: MenuItem | null = null
	): void {
		items.forEach((item) => {
			item.parent = parent;

			if (parent && parent.path) {
				item.path = `${parent.path} > ${item.label}`;
			} else {
				item.path = item.label;
			}

			if (item.children) {
				this.assignParents(item.children, item);
			}
		});
	}

	public isSubMenuSelected(child: MenuItem): boolean {
		return this.router.isActive(child.link!, {
			paths: 'subset',
			queryParams: 'ignored',
			fragment: 'ignored',
			matrixParams: 'ignored',
		});
	}

	public toggleMenu(item: MenuItem): void {
		if (this.isSidebarMinimized) {
			return;
		}

		if (this.isMenuExpanded(item)) {
			this.expandedMenus.delete(item.label);
		} else {
			this.collapseSiblingMenus(item);
			this.expandedMenus.add(item.label);
		}
	}

	private collapseSiblingMenus(item: MenuItem): void {
		const siblings = item.parent ? item.parent.children : this.menuItems;

		if (siblings) {
			siblings.forEach((sibling) => {
				if (sibling !== item) {
					this.expandedMenus.delete(sibling.label);
					this.collapseAllDescendants(sibling);
				}
			});
		}
	}

	private collapseAllDescendants(item: MenuItem): void {
		if (item.children) {
			item.children.forEach((child) => {
				this.expandedMenus.delete(child.label);
				this.collapseAllDescendants(child);
			});
		}
	}

	public isMenuExpanded(item: MenuItem): boolean {
		return this.expandedMenus.has(item.label);
	}

	public toggleSidebar(): void {
		this.sidebarService.toggleMinimization();
		this.isSidebarMinimized = !this.isSidebarMinimized;

		if (this.isSidebarMinimized) {
			this.expandedMenus.clear();
			console.log(this.selectedMenu);
		} else {
			this.selectMenuItemBasedOnRoute();
		}
	}

	public getLogoSrc(): string {
		const logoFile = this.isSidebarMinimized
			? 'logos/minimized-logo.svg'
			: 'logos/logo.svg';
		return `${this.basePath}${logoFile}`;
	}

	public getLogoWidth(): number {
		if (this.isSidebarMinimized) {
			return 30;
		} else {
			return this.theme === 'skin-epag' ? 153 : 204;
		}
	}

	public toggleCurrencyModal(): void{
		this.currencyModal = !this.currencyModal;
	}

	public togglePersonalInfoModal(): void{
		this.personalInfoModal = !this.personalInfoModal;
	}

	private selectMenuItemBasedOnRoute(): void {
		const activeUrl = this.router.url;

		const findAndSelect = (items: MenuItem[]): boolean => {
			for (const item of items) {
				if (item.link && activeUrl.startsWith(item.link)) {
					this.selectedMenu = item;
					if (!this.isSidebarMinimized) {
						this.expandParents(item);
					}
					return true;
				}

				if (item.children && findAndSelect(item.children)) {
					if (!this.isSidebarMinimized) this.expandedMenus.add(item.label);
					return true;
				}
			}
			return false;
		};

		findAndSelect(this.menuItems);
	}

	public logout() {
		this.authService.logout();
	}

	private updatePayoutsMenuVisibility(isVisible: boolean): void {
		const payoutsMenuItem = this.menuItems.find(
			(item) => item.label === 'Payouts'
		);
		if (payoutsMenuItem) {
			payoutsMenuItem.isVisible = isVisible;
		}
	}

	private checkPayouts(): void {
		let hasPayouts = false;
		const cachedPayouts = this.cacheAPI.getItem('hasPayouts');

		if (cachedPayouts) {
			const payoutsArray = JSON.parse(cachedPayouts);
			hasPayouts = payoutsArray.length > 0;
			this.updatePayoutsMenuVisibility(hasPayouts);
		} else {
			this.contractsService
				.getContracts()
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe(
					({ contracts }) => {
						const payouts = contracts.filter(
							(contract: Contract) => contract.contractType === 'PAYOUT'
						);
						hasPayouts = payouts.length > 0;
						this.cacheAPI.setItem('hasPayouts', JSON.stringify(payouts));
						this.updatePayoutsMenuVisibility(hasPayouts);
					},
					(error) => {
						console.error('Erro ao obter projetos:', error);
						this.updatePayoutsMenuVisibility(false);
					}
				);
		}
	}

}
